<template>
  <footer class="footer">
    <div class="footer-section">
      <div class="wrapper">
        <div class="left">
          <ul class="nav">
            <li
              class="nav__item"
            >
              <a
                class="desc"
                @click="setProductCategory('all')"
              >
                <b>{{ $t('Market') }}</b>
              </a>
            </li>
            <li 
              v-for="item in $parent.textPageList" 
              :key="item.id"
              class="nav__item" 
            >
              <a 
                class="desc"
                @click="$parent.goToPage(item.id)"
              >
                <b>{{ $t(item.title) }}</b>
              </a>
            </li>
          </ul>
        </div>
        <div class="center">
          <router-link 
            to="/" 
            class="logo"
          >
            <img 
              src="./../assets/logo2.svg" 
              class="img"
            >
          </router-link>
          <div class="desc">
            {{ $t($parent.footerRequisites) }}
          </div>
        </div>
        <div class="right">
          <div class="desc">
            {{ $t('Support:') }}
          </div>
          <a 
            :href="'mailto:' + $parent.footerSupportEmail"
            class="desc"
          >
            <b>{{ $parent.footerSupportEmail }}</b>
          </a>
          <div class="social-links-wrapper">
            <div class="social-links">
              <a target="_blank" :href="link.link" v-for="link in $parent.socials" class="link" v-bind:key="link.link">
                <img :src="link.img" class="link-img" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-section">
      <div class="wrapper">
        <div class="left">
          <div class="desc">
            {{ $t($parent.footerCopyright) }}
          </div>
        </div>
        <div class="right">
          <div class="payment-list">
            <div class="item">
              <img 
                class="img"
                src="./../assets/3ds.svg" 
              >
            </div>
            <div class="item visa">
              <img 
                class="img"
                src="./../assets/visa.svg" 
              >
            </div>
            <div class="item mc">
              <img 
                class="img"
                src="./../assets/mc_symbol.svg" 
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
		}
	},
	methods: {
    setProductCategory(category) {
      this.$emit('setProductCategory', category)
    },
	},
}
</script>
