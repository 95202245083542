<template>
  <div class="modal cart-modal">
    <div 
      class="overlay overlay-invisible" 
      @click="$parent.closeCart"
    />
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="cart-up">
                  <div class="cart-top">
                    <div class="title">
                      <b>{{ $t('Cart') }}</b>
                    </div>
                    <img 
                      class="close" 
                      src="./../assets/close.svg"
                      @click="$parent.closeCart" 
                    >
                  </div>
                  <div class="product-list">
                    <div 
                      v-if="$parent.cartContents && !$parent.cartContents.length"
                      class="desc"
                    >
                      {{ $t('Cart is empty') }}
                    </div>
                    <div 
                      v-for="(item, i) in $parent.cartContents" 
                      :key="i"
                      class="item"
                    >
                      <div class="preview">
                        <img 
                          :src="$parent.imgDomain + item.item.img_url" 
                          class="img"
                        >
                      </div>
                      <div class="desc">
                        <b>{{ item.item.title }}</b>
                      </div>
                      <div class="desc count">
                        x{{ item.count }}
                      </div>
                      <div class="price desc">
                        <b>
                          <span class="currency">
                            {{ $parent.currency }}
                          </span>
                          {{ item.item.price }} 
                        </b>
                      </div>
                      <div 
                        class="delete" 
                        @click="$parent.removeFromCart(item.item, item.item_id)"
                      >
                        <img 
                          src="./../assets/bin.svg"
                          class="img"
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <label class="input-container">
                  <div class="desc">
                    {{ $t('Steam ID') }}
                  </div>
                  <input
                    v-model="steamId"
                    type="text"
                    required
                    :placeholder="$t('Steam ID')"
                  >
                </label>

                <label class="input-container">
                  <div class="desc">
                    {{ $t('Trade link') }}
                  </div>
                  <input
                    v-model="tradeUrl"
                    type="text"
                    required
                    :placeholder="$t('Enter your Steam trade link')"
                  >
                </label>
                
                <div class="cart-bottom">
                  <button 
                    class="button"
                    @click="buy" 
                  >
                    {{ $t('Pay') }}
                  </button>
                  <div class="desc">
                    <span>{{ $t('Total:') }}</span> {{ $parent.currency }} {{ $parent.totalSum }}
                  </div>
                </div>
                <transition name="slide">
                  <div 
                    v-if="$parent.error" 
                    class="error-desc desc red"
                  >
                    {{ $t($parent.error) }}
                  </div>
                </transition>
                <transition name="slide">
                  <div 
                    v-if="successMessage" 
                    class="desc green"
                  >
                    {{ $t(successMessage) }}
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Cart',
  props: [],
  data: function() {
    return {
      successMessage: '',
      balance: '',
      steamId: '',
      tradeUrl: '',
    }
  },
  mounted() {
    this.getBalance();
  },
  methods: {
    getBalance() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
          this.balance = res.data.balance;
          this.steamId = res.data.steamId;
          this.tradeUrl = res.data.steam_trade_link;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
      })
    },
    buy() {
      let data = {
        "steamId": this.steamId,
        "tradeUrl": this.tradeUrl,
      }
      this.$http.post(process.env.VUE_APP_API + 'purchase/product', data)
      .then((res) => {
        let self = this;
        if (res.data.status == "OK") {
          this.$emit('setError', '');
          this.successMessage = 'Success';
          setTimeout(function() {
            self.$parent.closeCart();
            self.getBalance();
            self.$parent.getDepositHistory();
            self.$parent.getOrderHistory();
            self.$parent.getCartContents();
            self.$router.push({path: '/profile/orders'});
          }, 1500)
          
        } else {
          this.$emit('setError', res.data.message)
        }
      })
      .catch((res) => {
        this.$emit('setError', res.response.data.message)
      })
    }
  }
}
</script>