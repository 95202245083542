<template>
  <div 
    class="slider-container" 
  >
    <slick 
      ref="slick"  
      :options="slickOptions" 
      class="slider products"
      @swipe="handleSwipe"
    >
      <div 
        v-for="item in list" 
        :key="item.id"
        class="item" 
      >
        <ProductCard
          :categories="categories" 
          :currency="currency" 
          :is-auth="isAuth" 
          :item="item" 
          :slider-is-clickble="sliderIsClickble" 
          @goToProduct="goToProduct" 
          @openSignInModal="openSignInModal"
          @openBuyModal="openBuyModal" 
          @openReadyModal="openReadyModal" 
          @setProductCategory="setProductCategory" 
          @addToCart="addToCart"
        />
      </div>
    </slick>
  </div>
</template>

<script>
import ProductCard from '../components/ProductCard.vue'
import Slick from 'vue-slick';

export default {
  name: 'SlickItem',
  components: {
    Slick,
    ProductCard
  },
  props: {
		list: {
			type: Array,
			required: true
		},
		currency: {
			type: String,
			required: true
		},
    isAuth: {
			type: Boolean,
			required: true
		},
    categories: {
			type: Array,
			required: true
		},
	},
  data: function() {
    return {
      imgDomain: '',
      sliderIsClickble: true,
      slickOptions: {
        slidesToShow: 5,
        arrows: true,
        infinite: false,
        dots: false,
        lazyLoad: 'ondemand',
        responsive: [
          {
            breakpoint: 1201,
            settings: {
              slidesToShow: 4,
            }
          },
          {
            breakpoint: 901,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 601,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 450,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      },
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    addToCart(item, id) {
      this.$parent.addToCart(item, id);
    },
    handleSwipe() {
      let self = this;
      self.sliderIsClickble = false;
      setTimeout(()=> {
        self.sliderIsClickble = true;
      }, 300)
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    openReadyModal() {
			this.$emit('openReadyModal');
		},
    openSignInModal() {
      this.$emit('openSignInModal');
    },
    openBuyModal(item, type) {
      this.$emit('openBuyModal', item, type)
    },
    setProductCategory(category) {
      this.$emit('setProductCategory', category)
    }
  }
}
</script>