<template>
  <div 
    class="item-wrapper" 
  >
    <div 
      class="item-top"
      @click="goToSliderItem(item)"
    >
      <div class="preview">
        <img 
          :src="imgDomain + item.img_url" 
          class="img"
        >
        <transition name="fade">
          <span 
            v-if="addToCartChosenItem == item.id"
            class="desc desc-added" 
          >
            {{ $t('Added') }}
          </span>
        </transition>
      </div>
    </div>
    <div class="item-bottom">
      <div class="price desc">
        <span 
          v-if="item.old_price != '0.00'"
          class="discount" 
        >
          <span class="currency">
            {{ currency }}
          </span>
          {{ item.old_price }}
        </span>
        <span>
          <span class="currency">
            <b>{{ currency }} </b>
          </span> 
          <b>{{ item.price }}</b>
        </span>
        <span 
          v-if="discountPercentage > 0" 
          class="percent" 
        >
          <b>-{{ discountPercentage }}%</b>
        </span>
      </div>
      <div class="desc tag">
        {{ item.type }}
      </div>
      <div 
        class="title-container"
        @click="goToSliderItem(item)"
      >
        <div 
          class="desc" 
        >
          <b>{{ item.title }}</b>
        </div>
      </div>
      <button 
        class="button"
        @click="$emit('addToCart',item, item.id)"
      >
        {{ $t('Add to cart') }}
      </button>
    </div>
  </div>
</template>

<script>

export default {
	name: 'ProductCard',
  
	props: {
    addToCartChosenItem: {
			type: [String, Object],
			required: true
		},
		item: {
			type: [String, Object],
			required: true
		},
		sliderIsClickble: {
			type: Boolean,
			required: true
		},
		currency: {
			type: String,
			required: true
		},
		categories: {
			type: Array,
			required: true
		},
	},
	data: function() {
		return {
			imgDomain: '',
      categoryImg: ''
		}
	},
	computed: {
		discountPercentage() {
			if (parseFloat(this.item.old_price) > 0) {
				const oldPrice = parseFloat(this.item.old_price);
				const newPrice = parseFloat(this.item.price);
				const discount = ((oldPrice - newPrice) / oldPrice) * 100;
				return discount.toFixed(0);
			} else {
				return 0;
			}
		},
	},
	mounted() {
		this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
	},
	methods: {
		goToSliderItem(item) {
			this.$emit('goToProduct', item);
		},
		setProductCategory() {
			let self = this;
      if (this.categories && this.categories.length) {
        setTimeout(()=> {
          if (self.sliderIsClickble == true) {
            if (self.categories && self.categories.length) {
              self.$emit('setProductCategory', this.item)
            }
          }
        }, 200)
      } else {
        this.goToSliderItem(this.item);
      }
		},
	},
}
</script>
