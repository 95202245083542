<template>
  <main class="main product-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="page-inside-top">
          <div class="wrapper">
            <div class="bradcrumbs">
              <div class="desc">
                {{ $t('CS2') }}
              </div>
              <div class="desc">
                {{ $t('All items') }}
              </div>
              <div class="desc">
                {{ $t($parent.productPageItem.type) }}
              </div>
              <div class="desc active">
                {{ $t($parent.productPageItem.title) }}
              </div>
            </div>
          </div>
        </div>
        <div class="page-inside-wrapper">
          <div class="wrapper">
            <transition name="fade">
              <div 
                v-if="$parent.productPageItem"
                class="product-preview" 
              >
                <div class="preview-container">
                  <div class="preview-wrapper">
                    <div class="preview">
                      <transition name="fade">
                        <span 
                          v-if="$parent.addToCartChosenItem == $parent.productPageItem.id"
                          class="title" 
                        >
                          {{ $t('Added') }}
                        </span>
                      </transition>
                      <img 
                        class="img"
                        :src="$parent.imgDomain + $parent.productPageItem.img_url" 
                      >
                    </div>
                    <div class="text">
                      <div class="desc type">
                        {{ $t($parent.productPageItem.type) }}
                      </div>
                      <div class="title name">
                        {{ $t($parent.productPageItem.title) }}
                      </div>
                      <div class="title price">
                        <span class="currency">{{ $parent.currency }}</span>
                        {{ $parent.productPageItem.price }}
                      </div>
                      <button 
                        class="button orange" 
                        @click="$parent.addToCart($parent.productPageItem, $parent.productPageItem.id)"
                      >
                        <span>{{ $t("Add to cart") }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </section>
      <div class="section arrivals-section recommended-section">
        <div class="top">
          <div class="title spec">
            {{ $t('RECOMMEND') }}
          </div>
          <div 
            class="button dark"
            @click="setProductCategory('all')"
          >
            {{ $t('See all') }}
            <img 
              src="./../assets/arrow.svg" 
              class="img"
            >
          </div>
        </div>
        <div 
          v-if="newList && newList.similar && newList.similar.length"
          class="wrapper"
        >
          <SlickItem 
            :currency="currency"
            :is-auth="$parent.isAuth"
            :categories="$parent.categoryOptions"
            :list="newList.similar" 
            @setProductCategory="setProductCategory"
            @goToProduct="goToProduct"
            @addToCart="addToCart"
          />
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import SlickItem from '../components/SlickItem.vue';

export default {
  name: "ProductPage",
  components: {
    SlickItem
  },
  props: {
    productPageItem: {
      type: [Object, String],
      required: true
    },
    addToCartChosenItem: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    currencyCode: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      newList: ''
    };
  },
  watch: {
    currencyCode() {
      this.$parent.getProductPageContent(this.$route.params.id);
    },
    productPageItem() {
      let self = this;
      setTimeout(() => {
        self.newList = self.productPageItem;
      }, 100);
    }
  },
  mounted() {
    this.$parent.getProductPageContent(this.$route.params.id);
    let self = this;
    setTimeout(() => {
      self.newList = self.productPageItem;
    }, 100);
  },
  methods: {
    addToCart(item, id) {
      this.$parent.addToCart(item, id);
    },
    goToProduct(item) {
      this.$parent.goToProduct(item);
    },
    setProductCategory(category) {
      this.$emit('setProductCategory', category)
    },
  }
}
</script>
