<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper home">
          <div class="title">
            {{ $t('UPGRADE YOUR GEAR') }}
          </div>
          <div class="title big">
            {{ $t('BROWSE CS2 SKINS HERE') }}
          </div>
          <div class="desc">
            {{ $t('Are you on the hunt for the perfect skin to enhance your CS2 gameplay? *website name* offers a wide selection of high-quality CS2 skins to suit every taste and style.') }}
          </div>
          <div class="buttons">
            <div
              class="button"
              @click="setProductCategory('all')"
            >
              {{ $t('Buy now') }}
            </div>
            <div
              v-if="!$parent.isAuth"
              class="button dark"
              @click="$parent.openSignIn()"
            >
              {{ $t('Sign in') }}
            </div>
          </div>
        </div>
      </div>
      <div class="section how-it-works-section">
        <div class="wrapper">
          <img 
            src="./../assets/howItWorks/text.svg" 
            class="text"
          >
          <div class="title title-main">
            {{ $t('How It Works') }}
          </div>
          <div class="list">
            <div class="item">
              <img 
                src="./../assets/howItWorks/1.png" 
                class="img"
              >
              <div class="title small">
                {{ $t('BROWSE') }}
              </div>
              <div class="desc">
                {{ $t('Explore our collection of CS2 skins and discover a wide range of designs to choose from.') }}
              </div>
            </div>
            <div class="item">
              <div class="title small">
                {{ $t('SELECT') }}
              </div>
              <div class="desc">
                {{ $t('Once you\'ve found the perfect skin, simply add it to your cart and proceed to checkout.') }}
              </div>
            </div>
            <div class="item">
              <img 
                src="./../assets/howItWorks/2.png"  
                class="img"
              >
              <div class="title small">
                {{ $t('ENJOY') }}
              </div>
              <div class="desc">
                {{ $t('Equip your new CS2 skin and take your gameplay to the next level with style and confidence.') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section home-products-section">
        <div class="wrapper">
          <div class="top">
            <div class="title">
              {{ $t('COUNTER-STRIKE 2 MARKET') }}
            </div>
            <button
              class="button dark"
              @click="setProductCategory('all')"
            >
              {{ $t('See all') }}
              <img 
                src="./../assets/arrow.svg" 
                class="img"
              >
            </button>
          </div>
          <SlickItem 
            v-if="$parent.newArrivals.length"
            :currency="currency"
            :is-auth="$parent.isAuth"
            :categories="$parent.categoryOptions"
            :list="$parent.newArrivals" 
            @setProductCategory="setProductCategory"
            @goToProduct="goToProduct"
            @addToCart="addToCart"
          />
        </div>
      </div>
      <div class="section join-section">
        <div class="wrapper">
          <div class="title">
            {{ $t('SIGN UP NOW TO START EXPLORING') }}
          </div>
          <div class="desc">
            {{ $t('our extensive collection of skins and take your CS2 experience to new heights!') }}
          </div>
          <div 
            class="button dark" 
            @click="setProductCategory('all')"
          >
            <span>{{ $t('Explore') }}</span>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import ProductCard from '../components/ProductCard.vue';
import SlickItem from '../components/SlickItem.vue';

export default {
  name: 'Home',
  components: {
    SlickItem
  },
  props: {
    addToCartChosenItem: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      imgDomain: '',
      list: []
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    setProductCategory(category) {
      this.$emit('setProductCategory', category)
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    },
  }
}
</script>