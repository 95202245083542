<template>
  <main class="main page-inside text-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="page-inside-top">
          <transition name="fade">
            <div 
              v-if="$parent.textPageTitle"
              class="wrapper" 
            >
              <div class="title">
                {{ $parent.textPageTitle }}
              </div>
              <div 
                class="desc" 
                v-html="$parent.textPageHeader"
              />
              <div 
                class="desc"  
                v-html="$parent.textPageContent"
              />
            </div>
          </transition>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'TextPage',
  components: {
  },
  props: [],
  data: function() {
    return {
      
    }
  },
  mounted() {
    this.$parent.getTextPageContent(this.$route.params.id);
  },
  methods: {
    
  }
}
</script>